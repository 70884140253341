import React from 'react'

const strokeStyle = {vectorEffect: 'non-scaling-stroke'}

const SunIcon = () => (
  <svg
    viewBox='0 0 28.3 28.3'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    preserveAspectRatio='xMidYMid'
    width='1em'
    height='1em'
  >
    <path d='M27.2 13.6h-3c-.4 0-.8.3-.8.8s.3.8.8.8h3c.4 0 .8-.3.8-.8s-.4-.8-.8-.8zM4.9 14.3c0-.4-.3-.8-.8-.8h-3c-.4 0-.8.3-.8.8s.3.8.8.8h3c.5 0 .8-.4.8-.8zM14.2 6.8c-4.1 0-7.5 3.4-7.5 7.5s3.4 7.5 7.5 7.5 7.5-3.4 7.5-7.5-3.4-7.5-7.5-7.5zm0 13.5c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6zM14.2 23.6c-.4 0-.8.3-.8.8v3c0 .4.3.8.8.8s.8-.3.8-.8v-3c-.1-.5-.4-.8-.8-.8zM14.2 5.1c.4 0 .8-.3.8-.8v-3c0-.4-.3-.8-.8-.8s-.8.3-.8.8v3c0 .4.4.8.8.8zM6.6 20.9L4.5 23c-.3.3-.3.8 0 1.1.1.1.3.2.5.2s.4-.1.5-.2L7.6 22c.3-.3.3-.8 0-1.1s-.7-.3-1 0zM21.2 8c.2 0 .4-.1.5-.2l2.1-2.1c.3-.3.3-.8 0-1.1s-.8-.3-1.1 0l-2.1 2.1c-.3.3-.3.8 0 1.1.3.1.5.2.6.2zM21.8 20.9c-.3-.3-.8-.3-1.1 0s-.3.8 0 1.1l2.1 2.1c.1.1.3.2.5.2s.4-.1.5-.2c.3-.3.3-.8 0-1.1l-2-2.1zM6.6 7.8c.1.1.3.2.5.2s.4-.1.5-.2c.3-.3.3-.8 0-1.1L5.5 4.6c-.3-.3-.8-.3-1.1 0s-.3.8 0 1.1l2.2 2.1z' style={strokeStyle} stroke='transparent' />

  </svg>
)

export default SunIcon
