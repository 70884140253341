import React from 'react'

const strokeStyle = {vectorEffect: 'non-scaling-stroke'}

const MoonIcon = () => (
  <svg
    viewBox='0 0 67 67'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    preserveAspectRatio='xMidYMid'
    width='1em'
    height='1em'
  >
    <path d='M65.2 49.2c-2.5.9-5.2 1.3-7.9 1.3-13.6 0-24.7-11.1-24.7-24.7 0-5.5 1.8-10.7 5.1-15 .3-.3.3-.8 0-1.2-.3-.4-.7-.5-1.1-.4C25.7 12.9 18.4 23 18.4 34.5c0 2.1.3 4.1.7 6-1-.4-2.1-.7-3.2-.7-4 0-7.3 3.1-7.5 6.9-4.3-.2-7.9 3.1-7.9 7.2 0 4 3.4 7.2 7.5 7.2h20c2.3 0 4.3-1 5.7-2.6 3.5 1.7 7.5 2.6 11.4 2.6 8.4 0 16.1-3.8 21.2-10.5.3-.4.3-.8 0-1.2-.2-.2-.7-.3-1.1-.2zM28 59.3H8c-3 0-5.5-2.3-5.5-5.2S5 48.8 8 48.8c.4 0 .8 0 1.2.1.3.1.7 0 .9-.2.2-.2.4-.5.3-.9 0-.2-.1-.5-.1-.7 0-2.9 2.5-5.2 5.5-5.2 2.1 0 3.9 1.1 4.9 2.8.2.4.6.6 1 .5.4 0 .7-.3.9-.7.3-1.1 1.4-1.9 2.6-1.9 1.5 0 2.7 1.2 2.7 2.6 0 .7-.3 1.3-.8 1.8-.3.3-.4.7-.2 1.1.2.4.5.6.9.6h.1c3 0 5.5 2.3 5.5 5.2S31 59.3 28 59.3zm17.1 0c-3.5 0-7.1-.8-10.3-2.2.4-.9.7-1.9.7-3 0-3.4-2.5-6.3-5.8-7.1.2-.5.3-1.1.3-1.7 0-2.5-2.1-4.6-4.7-4.6-1.4 0-2.7.6-3.6 1.6-.8-2.5-1.3-5.1-1.3-7.8 0-9.5 5.4-18.1 13.8-22.2-2.4 4.1-3.6 8.7-3.6 13.4 0 14.7 12 26.7 26.7 26.7 1.8 0 3.6-.2 5.3-.5-4.6 4.7-10.9 7.4-17.5 7.4z' /><path d='M44.2 23.8h16c3.4 0 6.2-2.7 6.2-6 0-2.8-2-5.1-4.6-5.8.1-.4.2-.8.2-1.2 0-2.1-1.8-3.9-4-3.9-1.2 0-2.2.5-3 1.3-1.2-1.2-2.8-1.9-4.5-1.9-3.3 0-6 2.5-6.2 5.6-3.4-.1-6.3 2.7-6.3 6 0 3.2 2.8 5.9 6.2 5.9zm0-10c.3 0 .6 0 .9.1.3.1.7 0 .9-.2.2-.2.4-.5.3-.9v-.5c0-2.2 1.9-4 4.2-4 1.6 0 3 .8 3.8 2.2.2.4.6.6 1 .5.4 0 .7-.3.9-.7.2-.8 1-1.4 1.9-1.4 1.1 0 2 .8 2 1.9 0 .5-.2 1-.6 1.3-.3.3-.3.7-.2 1.1.2.4.6.6 1 .6 2.3 0 4.2 1.8 4.2 4s-1.9 4-4.2 4h-16c-2.3 0-4.2-1.8-4.2-4-.1-2.2 1.8-4 4.1-4z' style={strokeStyle} stroke='transparent' />

  </svg>
)

export default MoonIcon
