import React from 'react'

const strokeStyle = {vectorEffect: 'non-scaling-stroke'}

const GiftListIcon = () => (
  <svg
    viewBox='0 0 64 48'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    preserveAspectRatio='xMidYMid'
    width='1em'
    height='1em'
  >
    <path d='M60.4325,32.9835H57.4916c1.3626-2.4018,1.0979-5.1075-.2941-6.5584a4.9017,4.9017,0,0,0-4.6075-1.098V1.6131a.98.98,0,0,0-.98-.98H8.4754A6.8622,6.8622,0,0,0,1.6131,7.495a.98.98,0,0,0,.98.98H13.377V62.3932a.98.98,0,0,0,.98.98H60.4325a.98.98,0,0,0,.98-.98V42.61a2.9409,2.9409,0,0,0,1.9606-2.7645V35.9244A2.9409,2.9409,0,0,0,60.4325,32.9835Zm-4.6271-5.2056c.98,1.0588.9117,3.3822-.7156,5.2056H48.61v-.1471a5.6664,5.6664,0,0,1,2.52-4.9016C52.3743,27.2,54.7173,26.631,55.8054,27.7779ZM45.7277,40.8261v-5.882H49.649v5.882Zm3.9213,1.9606V61.4129H45.7277V42.7867ZM34.9441,40.8261a.98.98,0,0,1-.98-.98V35.9244a.98.98,0,0,1,.98-.98H43.767v5.882Zm4.51-13.0482c1.0882-1.1469,3.4311-.5784,4.6664.1667a5.6761,5.6761,0,0,1,2.5292,4.9016v.1373h-6.48C38.5419,31.16,38.4537,28.8367,39.4536,27.7779ZM3.6718,6.5147a4.9016,4.9016,0,0,1,9.6072,0Zm11.6658.98a6.8625,6.8625,0,0,0-2.0684-4.9016h37.36v23.41l-.5.2548a6.864,6.864,0,0,0-2.5,2.6959,6.8626,6.8626,0,0,0-2.5-2.6959c-2.1371-1.2842-5.3526-1.6665-7.0976.1667-1.3822,1.4509-1.6567,4.1566-.2843,6.5584H34.9441a2.941,2.941,0,0,0-2.941,2.9409v3.9213A2.941,2.941,0,0,0,33.9638,42.61V61.4129H15.3376ZM35.9244,42.7867H43.767V61.4129H35.9244ZM51.61,61.4129V42.7867h7.8426V61.4129Zm9.8033-21.5672a.98.98,0,0,1-.98.98H51.61v-5.882h8.8229a.98.98,0,0,1,.98.98Z' transform='translate(-0.6328 -0.6328)' style={strokeStyle} stroke='transparent' />
    <path d='M6.5147,11.083a.98.98,0,0,0-.9215-.647h0a.98.98,0,0,0-.9117.6372L.7014,21.8568A.9789.9789,0,0,0,.6328,22.2v36.272a4.9016,4.9016,0,0,0,9.8032,0V22.2a.9787.9787,0,0,0-.0588-.3333ZM4.5541,23.18V47.6883H2.5934V23.18Zm3.9213,0V47.6883H6.5147V23.18ZM2.5934,49.649h5.882V51.61H2.5934Zm2.941-35.36L8.0636,21.22H3.015Zm0,47.1242a2.941,2.941,0,0,1-2.941-2.941V53.57h5.882v4.9016A2.941,2.941,0,0,1,5.5344,61.4129Z' transform='translate(-0.6328 -0.6328)' style={strokeStyle} stroke='transparent' />
    <path d='M24.1606,11.4163H20.2393a.98.98,0,0,0-.98.98v3.9212a.98.98,0,0,0,.98.98h3.9213a.98.98,0,0,0,.98-.98V12.3967A.98.98,0,0,0,24.1606,11.4163Zm-.98,3.9213H21.22V13.377H23.18Z' transform='translate(-0.6328 -0.6328)' style={strokeStyle} stroke='transparent' />
    <path d='M24.1606,21.22H20.2393a.98.98,0,0,0-.98.98v3.9213a.98.98,0,0,0,.98.98h3.9213a.98.98,0,0,0,.98-.98V22.2A.98.98,0,0,0,24.1606,21.22Zm-.98,3.9213H21.22V23.18H23.18Z' transform='translate(-0.6328 -0.6328)' style={strokeStyle} stroke='transparent' />
    <path d='M24.1606,31.0228H20.2393a.98.98,0,0,0-.98.98v3.9213a.98.98,0,0,0,.98.98h3.9213a.98.98,0,0,0,.98-.98V32.0031A.98.98,0,0,0,24.1606,31.0228Zm-.98,3.9213H21.22V32.9835H23.18Z' transform='translate(-0.6328 -0.6328)' style={strokeStyle} stroke='transparent' />
    <path d='M24.1606,40.8261H20.2393a.98.98,0,0,0-.98.98v3.9213a.98.98,0,0,0,.98.98h3.9213a.98.98,0,0,0,.98-.98V41.8064A.98.98,0,0,0,24.1606,40.8261Zm-.98,3.9212H21.22V42.7867H23.18Z' transform='translate(-0.6328 -0.6328)' style={strokeStyle} stroke='transparent' />
    <path d='M28.0819,15.3376H47.6883a.98.98,0,1,0,0-1.9606H28.0819a.98.98,0,1,0,0,1.9606Z' transform='translate(-0.6328 -0.6328)' style={strokeStyle} stroke='transparent' />
    <path d='M36.9048,24.1605a.98.98,0,0,0-.98-.98H28.0819a.98.98,0,1,0,0,1.9607h7.8425A.98.98,0,0,0,36.9048,24.1605Z' transform='translate(-0.6328 -0.6328)' style={strokeStyle} stroke='transparent' />

  </svg>
)

export default GiftListIcon
